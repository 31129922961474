<script>
/**
 * Footer component
 */
import {getConfig} from '@/api/home'
export default {
  name: 'Footer',
  data(){
    return{
      configInfo:{}
    }
  },
  created(){
    this.getConfigHandler()
  },
  methods:{
    async getConfigHandler(){
      const {data} = await getConfig()
      this.configInfo=data
    }
  }
}
</script>

<template>
  <div>
    <!--START FOOTER ALTER-->
    <div class="footer-alt pb-0">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-center pull-none ">
              <ul class="list-inline social m-0">
                <li class="list-inline-item">
                  <a :href="configInfo.whats_app" class="social-icon">
                    <i class="mdi mdi-whatsapp"></i>
                  </a>
                </li>
                <li class="list-inline-item">
                  <a :href="configInfo.telegram"  class="social-icon">
                    <i class="mdi mdi-telegram"></i>
                  </a>
                </li>
              </ul>
            </div>
            <div class="clearfix mb-1"></div>
            <div class=" text-center pull-none">
              <p
                class="copy-rights text-muted  mb-2 mb-sm-0"
              >{{configInfo.beian}}</p>
            </div>
            
          </div>
        </div>
      </div>
    </div>
    <!--START FOOTER ALTER-->
  </div>
</template>
<style lang="scss" scoped>
.copy-rights{
  font-size: 12px;
}
</style>