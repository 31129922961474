<script>
import { getNav, getBanner, getConfig } from '@/api/home';
import SanitizedHtml from '@/components/SanitizedHtml.vue';
import Services from '@/components/services';
import Contact from '@/components/contact';
import Footer from '@/components/footer';
import Switcher from '@/components/switcher';
import digitalAnimation from '@/components/digitalAnimation.vue';
import industrySections from '@/components/industrySection.vue';
import bussnInfo from '@/components/bussnInfo.vue';
export default {
  components: {
    Services,
    Contact,
    Footer,
    //Switcher,
    SanitizedHtml,
    digitalAnimation,
    industrySections,
    bussnInfo,
  },
  data() {
    return {
      configData: {},
      navArr: [],
      banner: [],
      videoUrl: '',
    };
  },
  created() {
    this.getConfigHandler();
    this.getNavHandler();
    this.getBannerHandler();
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    async getConfigHandler() {
      try {
        const { data } = await getConfig();
        this.configData = data;
        document.title = data.name || 'Hiric';
      } catch (error) {
        console.error('Error fetching config:', error);
      }
    },
    async getNavHandler() {
      try {
        const { data } = await getNav();
        this.navArr = data;
      } catch (error) {
        console.error('Error fetching navigation items:', error);
      }
    },
    async getBannerHandler() {
      try {
        const { data } = await getBanner();
        this.banner = data;
      } catch (error) {
        console.error('Error fetching banner items:', error);
      }
    },
    handleScroll() {
      window.onscroll = function() {
      onwindowScroll();
    };
    var navbar = document.getElementById("navbar");
    function onwindowScroll() {
      if (
        document.body.scrollTop > 40 ||
        document.documentElement.scrollTop > 40
      ) {
        navbar.style.backgroundColor = "#272a33";
        navbar.style.padding = "10px";
      } else {
        navbar.style.backgroundColor = "";
        navbar.style.padding = "20px";
      }
    }

     
    }
  }
};
</script>
<template>
  <div class="home_page">
    <!-- Navbar -->
    <nav class="navbar navbar-expand-lg fixed-top  sticky sticky-dark p-0" id="navbar">
      <div class="container d-flex justify-content-start">
        <!-- LOGO -->
        <a class="navbar-brand logo text-uppercase" href="/">
          <b-img
            v-if="configData.logo"
            :src="configData.logo"
            fluid
            alt="logo-img"
            height="35px"
            width="35px"
            class="mx-auto d-block"
          />
          <i v-else class="mdi mdi-alien"></i>
          <!-- <span>{{ configData.name }}</span> -->
        </a>
      </div>
    </nav>

    <!-- Other content -->
    <div v-scroll-spy>
      <section class="section bg-home pb-0" id="home" :style="{'background': `url(${banner.length > 0 ? banner[0].image : require('@/assets/images/bg-home.jpg')})`}">
        <div class="bg-overlay"></div>
        <div class="container_banner">
          <b-carousel id="carousel-1" :interval="5000" indicators>
            <b-carousel-slide v-for="(slide, index) in banner" :key="index" :img-src="slide.image">
              <div class="row text_box px-3">
                <div class="col-lg-12 offset-lg-1 text-white text-left" v-if="banner.length > 0">
                  <div class="home-title mb-4">
                    <SanitizedHtml :htmlContent="slide.title" />
                  </div>
                  <div class="home-small-title">
                    <SanitizedHtml :htmlContent="slide.sub_title" />
                  </div>
                  <p class="pt-2 home-desc text-left">
                    <SanitizedHtml :htmlContent="slide.content" />
                  </p>
                </div>
              </div>
            </b-carousel-slide>
          </b-carousel>
        </div>
      </section>
      
      <!-- Other sections -->
      <Services />
      <digitalAnimation />
      <industrySections />
      <bussnInfo />
      <Contact />
      <Footer />
      <!-- <Switcher /> -->
    </div>
  </div>
</template>
<style lang="scss" scoped>

/* Navbar starts transparent */
.navbar-custom {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000; /* 确保导航栏位于页面前端 */
  background-color: rgba(39, 42, 51, 0.9); /* 半透明背景 */
  transition: background-color 0.3s ease-in-out;
}
.navbar.scrolled {
  background-color: rgba(39, 42, 51, 1); /* 滚动后导航栏背景完全不透明 */
}


/* After scrolling past 100px, add a background color */
.transparent-navbar {
  background-color: transparent !important;
  box-shadow: none;
}

/* Adjust the logo size and style */
.navbar-brand {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: bold;
}

.logo img {
  max-height: 60px;
  width: auto;
  aspect-ratio: 16/9;
}

/* Other styles */
.bg-home {
  padding-top: 0px;
}
.navbar{
  padding: 3px 10%!important;
}
::v-deep .container_banner {
  .carousel-item {
    position: relative;
  }
  .text_box {
    position: absolute;
    left: 7%;
    padding-top: 80px;
  }
  .carousel-caption {
    top: 0 !important;
    left: 0 !important;
  }
}

.carousel-section {
  margin-top: 50px;
  width: 100%;
}

b-carousel-slide {
  text-align: center;
  background-color: #fff;
  position: relative;
}

::v-deep .carousel-item img {
  width: 100% !important;
  height: 600px;
  aspect-ratio: 18 / 9;
}

/* Mobile responsive styles */
@media (max-width: 768px) {
  .navbar{
    background-color: #272a33 !important;
  }
  .section {
    padding-top: 50px;
  }
  .logo img {
      max-height: 40px;
      width: auto;
      aspect-ratio: 18/9;
    }
   ::v-deep.carousel-item img {
    height: 380px!important;
    aspect-ratio: 16 / 9;
  }

  .text_box {
    padding-top: 10px !important;
  }

  ::v-deep.home-title{
    span,p{
        font-size: 1.8rem!important;
        line-height: 1.2;
      }

  }
  ::v-deep.home-small-title {
    span{
      font-size: 1.2rem!important;
      line-height: 27px!important;
    }
  }

  .home-desc {
    font-size: 1rem;
  }
}
</style>
