<template>
    <section class="my-section">
      <b-container>
        <b-row align-h="center" align-v="center">
          <b-col
            cols="12"
            md="8"
            lg="6"
            class="text-center"
            v-observe-visibility="onVisibilityChange"
          >
            <span class="number">{{animatedNumber }}<sup>+</sup> </span>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </template>
  
  <script>
  import { ObserveVisibility } from 'vue-observe-visibility';
  
  export default {
    directives: {
      ObserveVisibility,
    },
    props: {
      targetNumber: {
        type: Number,
        default: 0,
      },
      duration: {
        type: Number,
        default: 2000, // 动画持续时间（毫秒）
      },
    },
    data() {
      return {
        isVisible: false,
        animatedNumber: 0, // 用于显示的累加数字
      };
    },
    methods: {
      startCount() {
        const start = 0;
        const end = this.targetNumber;
        const duration = this.duration;
        let startTime = null;
  
        const animate = (timestamp) => {
          if (!startTime) startTime = timestamp;
          const progress = timestamp - startTime;
          const currentNumber = Math.min(
            Math.floor((progress / duration) * (end - start) + start),
            end
          );
          this.animatedNumber = currentNumber;
          if (progress < duration) {
            window.requestAnimationFrame(animate);
          }
        };
        window.requestAnimationFrame(animate);
      },
      onVisibilityChange(isVisible) {
        if (isVisible && this.animatedNumber === 0) {
          this.startCount(); // 当元素第一次进入视口时开始动画
        }
        this.isVisible = isVisible;
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .my-section {
    //height: 500px;
  }
  
  .number {
    font-size: 48px;
    font-weight: bold;
    text-align: center;
  }
  
  @media (max-width: 768px) {
    .number {
      font-size: 32px; /* 在移动设备上缩小字体 */
    }
  }
  
  @media (min-width: 769px) {
    .number {
      font-size: 48px; /* 在PC端增加字体大小 */
    }
  }
  </style>
  