<template>
  <!-- CONTACT SECTION WITHOUT FORM -->
  <section class="section" id="contact">
    <div class="container">
      <!-- 标题部分 -->
      <div class="row justify-content-center">
        <div class="col-lg-8 col-md-10 col-12 text-center">
          <div class="section-titles">
            <SanitizedHtml :htmlContent="contactInfo.title" />
          </div>
          <div class="section-title-border margin-t-15"></div>
          <!-- <hr class="divider margin-t-20"> -->
          <p class="section-subtitle text-muted font-secondary pt-3">
            <SanitizedHtml :htmlContent="contactInfo.content" />
          </p>
        </div>
      </div>
      <!-- 联系信息部分 -->
      <div class="row justify-content-center align-items-center mt-3">
        <div class="col-lg-auto  col-md-auto offset-lg-0 offset-md-0 mb-2 text-center">
            <a :href="config.whats_app" type="button" class="btn btn-primary rounded-pill">
              <i class="mdi mdi-whatsapp"></i>
              WhatsApp
            </a>
        </div>
        <div class="col-lg-auto col-md-auto offset-lg-0 offset-md-0 mb-2 text-center">
             <a :href="config.telegram" type="button" class="btn btn-success rounded-pill">
              <i class="mdi mdi-telegram"></i>
              Telegram
            </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {getContactInfo,getConfig} from '@/api/home';
import SanitizedHtml from '@/components/SanitizedHtml.vue';
export default {
  name: 'Contact',
  components:{SanitizedHtml},
  data(){
    return{
      contactInfo:{},
      config:{}
    }
  },
  created(){
    this.getContactInfoHandler()
  },
  methods:{
    async getContactInfoHandler(){
      const {data} = await getContactInfo()
      const config = await getConfig()
      this.contactInfo = data
      this.config = config.data
    }
  }
}
</script>

<style scoped>
/* 自定义样式 */
.section {
  padding: 60px 0;
}

.section-titles {
  font-size: 2rem;
}

.section-subtitle {
  font-size: 1rem;
  margin-top: 20px;
}

.contact-info h5 {
  font-weight: bold;
  font-size: 1.2rem;
}

.contact-info p {
  font-size: 1rem;
}

/* 移动端调整 */
@media (max-width: 768px) {
  .section {
  padding-top: 0!important;
  padding-bottom: 60px!important;
}
  .section-titles {
    font-size: 2rem!important;
    font-weight: 700!important;
  }

  .section-subtitle {
    font-size: 0.9rem;
  }

  .contact-info h5 {
    font-size: 1.1rem;
  }

  .contact-info p {
    font-size: 0.9rem;
  }
}
</style>