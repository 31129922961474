<template>
  <div>
    <!-- START SERVICES -->
    <section class="section bg-light" id="services">
      <div class="container">
        <div class="row">
          <div class="col-lg-8 offset-lg-2">
            <p class="section-titles text-center">
              <SanitizedHtml :htmlContent="detailsInfo.title" />
            </p>
            <div class="section-title-border margin-t-20"></div>
            <p class="section-subtitles text-muted text-center padding-t-30 font-secondary">
              <SanitizedHtml :htmlContent="detailsInfo.sub_title" />
            </p>
          </div>
        </div>
        <div class="grid-container margin-t-30">
          <div class="grid-item" v-for="(car, carIndex) in card" :key="carIndex">
            <div class="services-box">
              <div class="media">
                <i v-if="car.icon" class="text-custom" :class="[car.icon]"></i>
                <b-img v-if="car.image && !car.icon" :src="car.image" height="32" width="32"></b-img>
                <div class="media-body ml-4">
                 
                    <SanitizedHtml :htmlContent="car.title" />
               
                  <p class="pt-2 text-muted">
                    <SanitizedHtml :htmlContent="car.content" />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- END SERVICES -->
  </div>
</template>

<script>
import { getAbout, getDownload } from '@/api/home';
import SanitizedHtml from '@/components/SanitizedHtml.vue';
export default {
  name: 'Services',
  components:{SanitizedHtml},
  data() {
    return {
      card: [],
      detailsInfo: {},
      downInfo: {}
    };
  },
  created() {
    this.getAboutHandler();
  },
  methods: {
    async getAboutHandler() {
      try {
        const { data } = await getAbout();
        const downData = await getDownload();
        this.downInfo = downData.data;
        this.card = data.card;
        this.detailsInfo = data.detailsInfo;
      } catch (error) {
        console.error('Error fetching navigation items:', error);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
#services{
padding: 60px 0;
}
.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); /* PC端每行3列，最小300px宽度 */
  gap: 20px;
}

.grid-item {
  border: 1px solid #eaeaea;
  padding: 20px;
  border-radius: 10px; /* 添加圆角 */
}

.section-titles {
  font-size: 2rem;
}
.media-body{
  font-size: 18px;
}
@media (max-width: 768px) {
  #services{
    padding: 40px 0;
  }
  .grid-container {
    grid-template-columns: 1fr; /* 移动端一行一列 */
  }

  .section-titles {
    font-size: 1.5rem; /* 调整标题大小以适应移动端 */
    font-weight: 700;
  }

}
</style>
