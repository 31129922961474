var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg-account-pages py-4 py-sm-0"},[_vm._m(0),_c('section',{staticClass:"height-100vh"},[_c('div',{staticClass:"display-table"},[_c('div',{staticClass:"display-table-cell"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-lg-5"},[_c('div',{staticClass:"card account-card"},[_c('div',{staticClass:"card-body"},[_vm._m(1),_c('div',{staticClass:"p-3"},[_c('form',[_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_c('div',{staticClass:"mt-4 mb-0 text-center"},[_c('p',{staticClass:"mb-0"},[_vm._v(" Don't have an account ? "),_c('router-link',{staticClass:"text-danger",attrs:{"tag":"a","to":"/login"}},[_vm._v("Sign in")])],1)])])])])])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"account-home-btn d-none d-sm-block"},[_c('a',{staticClass:"text-white",attrs:{"href":"/"}},[_c('i',{staticClass:"mdi mdi-home h1"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center mt-3"},[_c('h3',{staticClass:"font-weight-bold"},[_c('a',{staticClass:"text-dark text-uppercase account-pages-logo",attrs:{"href":"/"}},[_c('i',{staticClass:"mdi mdi-alien"}),_vm._v("Hiric ")])]),_c('p',{staticClass:"text-muted"},[_vm._v("Sign up for a new Account")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"firstname"}},[_vm._v("First Name")]),_c('input',{staticClass:"form-control",attrs:{"type":"text","id":"firstname","placeholder":"First Name"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"email"}},[_vm._v("Email")]),_c('input',{staticClass:"form-control",attrs:{"type":"password","id":"email","placeholder":"Enter Email"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"userpassword"}},[_vm._v("Password")]),_c('input',{staticClass:"form-control",attrs:{"type":"password","id":"userpassword","placeholder":"Enter password"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"custom-control custom-checkbox"},[_c('input',{staticClass:"custom-control-input",attrs:{"type":"checkbox","id":"customControlInline"}}),_c('label',{staticClass:"custom-control-label",attrs:{"for":"customControlInline"}},[_vm._v("Remember me")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-3"},[_c('button',{staticClass:"btn btn-custom btn-block",attrs:{"type":"submit"}},[_vm._v("Sign in")])])
}]

export { render, staticRenderFns }