<!-- 数字动画展示 -->
<template>
<div class="statistics-section pb-5 pt-3 bg-light" :style="{backgroundImage: `url(${digitalInfo.image?digitalInfo.image:require('@/assets/images/bg_bd.png')})`}">
    <div class="row">
          <div class="col-lg-8 offset-lg-2">
            <div class="section-titles text-center">
                <SanitizedHtml :htmlContent="digitalInfo.title" />
            </div>
            <div class="section-title-border margin-t-20"></div>
            <p
              class="section-subtitles text-muted text-center padding-t-30 font-secondary"
            >
            <SanitizedHtml :htmlContent="digitalInfo.content" />
        </p>
          </div>
        </div>
    <b-container>
      <b-row class="text-center">
        <b-col md="3" v-for="(it,index) in digitalInfo.list" :key="index">
          <h2 class="display-4 font-weight-bold text-primary">
            <VueObserveVisibility :targetNumber="it.number" :duration="it.duration"/>
          </h2>
          <p class="lead">
            <SanitizedHtml :htmlContent="it.description" />
        </p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import VueObserveVisibility from '@/components/vue-observe-visibility.vue';
import SanitizedHtml from '@/components/SanitizedHtml.vue';
import {getDigital} from '@/api/home'
export default {
components: {VueObserveVisibility,SanitizedHtml},
data() {
return {
    digitalInfo:{},
    // dataList:[
    //     {title:'12年核心团队经验',number:2222,duration:3000},
    //     {title:'18年核心团队经验',number:33,duration:4000}
    // ]
};
},
computed: {},
watch: {},
methods: {
    async getDigitalHandler(){
        const {data} = await getDigital()
        this.digitalInfo=data
    }
},
created() {
    this.getDigitalHandler()
},
mounted() {

},
}
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
.statistics-section{
    background-size: cover;
    background-position: center center;
}
.section-titles{
  font-size: 2rem;
}
@media (max-width: 768px) {
  .section-titles {
    font-size: 2rem!important; /* 调整标题大小以适应移动端 */
    font-weight: 700!important;
  }

}
</style>