<template>
  <b-container fluid class="indu_page text-center my-5">
    <!-- Ensure industInfo.module is defined before trying to display its properties -->
    <div class="mb-3 page_title" v-if="industInfo.module">
      <SanitizedHtml :htmlContent="industInfo.module.title" />
    </div>
    <p class="mb-5" v-if="industInfo.module">
      <SanitizedHtml :htmlContent="industInfo.module.description" />
    </p>

    <div class="grid-layout" v-if="industInfo.list && industInfo.list.length">
      <!-- 使用grid布局展示行业信息 -->
      <div class="grid-item" v-for="(indu, induIndex) in industInfo.list" :key="induIndex">
        <b-card class="p-1 rounded-lg">
          <b-img :src="indu.icon_path" fluid alt="行业图标" class="mb-3"></b-img>
          <h5>
            <SanitizedHtml :htmlContent="indu.name" />
          </h5>
        </b-card>
      </div>
    </div>
  </b-container>
</template>


<script>
import { getIndustry } from '@/api/home';
import SanitizedHtml from '@/components/SanitizedHtml.vue';
export default {
  name: 'IndustrySection',
  components:{SanitizedHtml},
  data() {
    return {
      industInfo: {} // Initialize industInfo as an empty object
    };
  },
  created() {
    this.getIndustryHandler();
  },
  methods: {
    async getIndustryHandler() {
      try {
        const { data } = await getIndustry();
        this.industInfo = data;
      } catch (error) {
        console.error("Error fetching industry info:", error);
      }
    }
  }
};
</script>


<style lang="scss" scoped>
.indu_page {
  width: 70%;
  margin: 0 auto;
}

.grid-layout {
  display: grid;
  grid-template-columns: repeat(5, 1fr); /* 每行展示5列 */
  gap: 20px; /* 控制列间的间距 */
}

.grid-item {
  text-align: center;
  border-radius: 12px;
  .card{
    background-color: rgb(252 252 252)!important;
    border-radius: 12px!important;
  }
}

h2 {
  font-size: 2rem;
  font-weight: bold;
}

h5 {
  font-size: 1.1rem;
  font-weight: 600;
}

b-img {
  max-width: 80px; /* 控制图标大小 */
}
.page_title{
  font-size: 2rem;
}
@media (max-width: 768px) {
  .page_title{
  font-size: 2rem!important;
  font-weight: 700!important;
}
  .indu_page {
    width: 100%;
  }
  .grid-layout {
    grid-template-columns: repeat(3, 1fr); /* 移动端每行展示3列 */
    gap: 10px;
  }

  h2 {
    font-size: 1.5rem;
  }

  h5 {
    font-size: 1rem;
  }
}

</style>
