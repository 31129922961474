<template>
    <b-container fluid class="business-intro text-center my-5">
      <!-- 业务简介标题和描述 -->
      <div class="mb-3 page_title">
        <SanitizedHtml :htmlContent="industInfo.title" />
    </div>
      <p class="mb-5">
        <SanitizedHtml :htmlContent="industInfo.content" />
    </p>
  
      <!-- 世界地图部分 -->
      <div class="world-map mb-5">
        <img :src="industInfo.image||require('@/assets/images/world-map.svg')" alt="世界地图" class="world_map img-fluid" />
      </div>
  
      <!-- 合作伙伴信息和收单信息 -->
      <div class="partners-wrapper d-flex justify-content-between text-left">
        <div class="partners-info" v-for="(coop,index) in industInfo.list" :key="index">
          <h4 class="mb-3">
            <SanitizedHtml :htmlContent="coop.title" />
        </h4>
          <p>
            <SanitizedHtml :htmlContent="coop.description" />
          </p>
        </div>
      </div>
      
    </b-container>
  </template>
  
  <script>
  import { getCooperation } from '@/api/home';
  import SanitizedHtml from '@/components/SanitizedHtml.vue';
  export default {
    name: 'BusinessIntro',
    components:{SanitizedHtml},
    data() {
      return {
        industInfo: {}
      };
    },
    created() {
      this.getCooperationHandler();
    },
    methods: {
      async getCooperationHandler() {
        const { data } = await getCooperation();
        this.industInfo = data;
      }
    }
  };
  </script>
  
  <style lang="scss" scoped>
  .business-intro {
    max-width: 90%;
    margin: 0 auto;
  }
  
  h2 {
    font-size: 2rem;
    font-weight: bold;
  }
  
  p {
    font-size: 1rem;
    line-height: 1.5;
  }
  
  .world-map img {
    max-width: 100%;
    height: auto;
    aspect-ratio: 16 / 9;
  }
  
  /* 使用flex布局让两个模块横向排列 */
  .partners-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 2rem;
  }
  
  .partners-info, .partners-details {
    flex: 1;
    margin: 0 1rem;
  }
  .page_title{
    font-size: 2rem;
  }
  /* PC端样式 */
  @media (min-width: 992px) {
    h2 {
      font-size: 2.5rem;
    }
    .world-map img {
      max-width: 80%;
    }
  }
  
  /* 移动端样式 */
  @media (max-width: 768px) {
    h2 {
      font-size: 1.8rem;
    }
  
    p {
      font-size: 0.9rem;
    }
    .page_title{
    font-size: 2rem!important;
    font-weight: 700!important;
  }
    .world-map img {
      max-width: 100%;
    }
  
    /* 移动端时让两个模块垂直排列 */
    .partners-wrapper {
      flex-direction: column;
    }
  
    .partners-info, .partners-details {
      margin: 1rem 0;
    }
  }
  </style>
  