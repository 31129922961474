<!--安全解析html模板-->
<template>
    <div>
      <div v-html="sanitizedContent"></div>
    </div>
  </template>
  
  <script>
  import DOMPurify from 'dompurify';
  
  export default {
    name: 'SanitizedHtml',
    props: {
      htmlContent: {
        type: String,
        default:'',
        required: true
      }
    },
    computed: {
      sanitizedContent() {
        return DOMPurify.sanitize(this.htmlContent);
      }
    }
  };
  </script>
  